import React, { useState } from "react";

const AccordionItem = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`accordion-item ${isActive ? "active" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        {title}
      </div>
      <div
        className="accordion-content"
        style={{ maxHeight: !isActive ? "0" : undefined }}
      >
        <div>{content}</div>
      </div>
    </div>
  );
};

const GDPR = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="section min">
      <h2>DATENSCHUTZINFORMATIONEN GEMÄß ART. 12-14 DSGVO</h2>
      <div>
        <b>Wir verarbeiten personenbezogene Daten zu folgenden Zwecken:</b>
      </div>
      <div className="accordion">
        <AccordionItem
          title="1. Betrieb der Webseite und unserer IT-Systeme (Wartung und Sicherheit)"
          content={
            <>
              <h3>Zwecke der Verarbeitung</h3>
              <p>
                Zum Betrieb und zur Wartung von IT-Systemen und Firmenwebsites
                sowie zur Aufrechterhaltung der Datensicherheit und zur
                Verhinderung von missbräuchlichem bzw. betrügerischem Verhalten
                verarbeiten wir personenbezogene Daten unserer Nutzer
                grundsätzlich nur, soweit dies zur Bereitstellung der Dienste
                sowie unserer Inhalte und Leistungen erforderlich ist.
              </p>
              <p>&nbsp;</p>
              <h3>
                Rechtsgrundlagen und (überwiegende) berechtigte Interessen
              </h3>
              <p>
                Wir verarbeiten Ihre Daten für vorvertragliche Maßnahmen oder
                vertragliche Verpflichtungen (z.B. Erfüllung von AGB,
                Nutzungsbedingungen, Art. 6 Abs. 1 lit. b DSGVO), soweit
                gesetzlich erforderlich (Art. 6 Abs. 1 lit. c DSGVO, z.B.
                Aufrechterhaltung von angemessener Datensicherheit) sowie auf
                Basis von Art. 6 Abs. 1 lit. f DSGVO für folgende (überwiegende)
                berechtigte Interessen:
              </p>
              <ul>
                <li>
                  Sicherstellung eines funktionsfähigen Internetauftritts,
                </li>
                <li>Aufrechterhaltung der Datensicherheit</li>
                <li>effiziente interne Verwaltung in der Unternehmensgruppe</li>
              </ul>
              <p>&nbsp;</p>
              <h3>
                Empfänger oder Kategorien von Empfängern und Übermittlungen in
                Drittländer oder an internationale Organisationen
              </h3>
              <p>
                Übermittlungen sind nicht vorgesehen, von Nutzern unserer
                Dienste selbst veröffentlichte Daten (z.B. Kommentare) sind
                jedoch öffentlich und potenziell weltweit zugänglich. Im
                Anlassfall können die Daten an Strafverfolgungsbehörden,
                Gerichte etc. zur Geltendmachung von Rechtsansprüchen des
                Verantwortlichen oder Dritter übermittelt werden.
              </p>
              <p>Wir nutzen die Dienste folgender Auftragsverarbeiter:</p>
              <p>Derzeit keine Auftragsverarbeiter</p>
              <h3>Erhebung von Daten aus anderen Quellen</h3>
              <p>Herkunft (Quellen) und Kategorien personenbezogener Daten:</p>
              <p>
                Bei jedem Aufruf unserer Internetseite erfassen unsere Systeme
                automatisiert Daten und Informationen vom Endgerät der
                Betroffenen. Folgende Kategorien personenbezogener Daten werden
                hierbei erhoben:
              </p>
              <ul>
                <li>
                  Informationen über den Browsertyp und die verwendete Version
                </li>
                <li>Das Betriebssystem des Nutzers</li>
                <li>Den Internet-Service-Provider des Nutzers</li>
                <li>Die IP-Adresse des Nutzers, Session-ID des Browsers</li>
                <li>Datum und Uhrzeit des Zugriffs</li>
                <li>
                  Daten zum Endgerät (Geräte-ID, Sprach- und
                  Ländereinstellungen)
                </li>
                <li>Cookie-Einstellungen</li>
                <li>
                  Websites, von denen das System des Nutzers auf unsere
                  Internetseite gelangt
                </li>
                <li>
                  Websites, die vom System des Nutzers über unsere Website
                  aufgerufen werden.
                </li>
              </ul>
              <p>&nbsp;</p>
              <h3>Speicherdauer</h3>
              <p>
                Daten, die für die Funktionsfähigkeit des Internetauftritts
                notwendig sind, werden nur für die Dauer Ihres Besuches unserer
                Seiten verarbeitet und gespeichert. Logdaten und IP-Adressen
                werden zur Abwehr von Angriffen auf unsere Systeme darüber
                hinaus im Regelfall für 1 Jahr gespeichert, zur Aufklärung von
                Datensicherheitsvorfällen und zur Geltendmachung von
                Schadensersatzansprüchen im Anlassfall bis zur abschließenden
                Erledigung. Dies geschieht aufgrund einschlägiger gesetzlicher
                Bestimmungen.
              </p>
              <p>&nbsp;</p>
              <h3>Cookies</h3>
              <p>
                Cookies werden ausschließlich zu Authentifikationszwecken
                (Anmeldung für den internen Bereich der Website) ausgewertet.
              </p>
              <p>
                Diese Website funktioniert vollinhaltlich auch bei höchsten
                Sicherheitseinstellungen in Ihrem Browser. Insbesondere werden
                keinerlei permanenten Cookies oder ähnliche Maßnahmen
                eingesetzt, um benutzerspezifische Profile anzulegen oder
                auszuwerten. Serverlogs, auch solche aufgrund gesetzlicher
                Verpflichtungen, werden nicht zur Erstellung von
                personenbezogenen Benutzerprofilen verwendet.
              </p>
              <p>
                Ohne Einwilligung werden ausschließlich Ihre
                Cookie-Einstellungen für 365 Tage auf Ihrem Endgerät
                gespeichert.{" "}
                {/*Zur Verwendung des Cookies von „Google Analytics“
                finden Sie alle Hinweise bei Punkt 3 „Marketing“*/}
              </p>
              <p>&nbsp;</p>
              <h3>Bereitstellung von Daten</h3>
              <p>
                Es besteht keine gesetzliche oder vertragliche Verpflichtung zur
                Bereitstellung der Daten, diese sind jedoch erforderlich für die
                Funktionsfähigkeit des Internetauftritts und unserer IT-Systeme,
                die ansonsten ggf. beeinträchtigt sein kann.
              </p>
              <p>&nbsp;</p>
              <h3>Weiterverarbeitung Ihrer Daten zu anderen Zwecken</h3>
              <p>
                Ein Teil der erhobenen Daten wird mit Ihrer Einwilligung für
                Marketingmaßnahmen weiterverarbeitet. Darauf und auf Ihre
                Widerspruchsrechte weisen wir bereits vor der Erhebung auf der
                Webseite hin. Wir bitten Sie, diesbezüglich Punkt 3 der
                Datenschutzinformation zu beachten.
              </p>
              <p>&nbsp;</p>
            </>
          }
          isActive={activeIndex === 0}
          onToggle={() => handleToggle(0)}
        />
        <AccordionItem
          title="2. Verarbeitungen im Zusammenhang mit der Erbringung von Lieferungen und Leistungen"
          content={
            <>
              <h3>Zwecke der Verarbeitung</h3>
              <p>
                <br /> Wir erheben personenbezogene Daten zur Lieferung unserer
                Produkte und Erbringung unserer Leistungen sowie zur
                Inanspruchnahme der Leistungen Dritter bei Geschäftsbeziehungen.
                <br /> &nbsp;
              </p>
              <h3>Rechtsgrundlagen und berechtigte Interessen</h3>
              <p>
                Rechtsgrundlagen für die Verarbeitung der Daten sind
                vorvertragliche Maßnahmen oder vertragliche Verpflichtungen
                (Art. 6 Abs. 1 lit. b DSGVO), rechtliche und gesetzliche
                Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO, insbesondere
                Steuer- und Abgabenrecht) sowie folgende (überwiegenden)
                berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO):
              </p>
              <ul>
                <li>
                  Geltendmachung oder Verteidigung eigener Rechtsansprüche,
                  insbesondere aus Gründen der Gewährleistung und Produkthaftung
                </li>
                <li>Verminderung von Ausfallrisiken (auch bei Dritten)</li>
                <li>
                  Beantwortung von Kundenanfragen zu Lieferungen und Leistungen;
                  es besteht ein jederzeitiges Widerspruchsrecht, soweit keine
                  überwiegenden Interessen (z.B. Haftung) entgegenstehen
                </li>
                <li>Effiziente interne Verwaltung im Unternehmen</li>
              </ul>
              <p>&nbsp;</p>
              <h3>
                Empfänger oder Kategorien von Empfängern und Übermittlungen in
                Drittländer oder an internationale Organisationen
              </h3>
              <p>
                <br /> Bei Notwendigkeit erfolgen Übermittlungen
                personenbezogener Daten an folgende Kategorien von Empfängern:
              </p>
              <ul>
                <li>Banken (zur Abwicklung des Zahlungsverkehrs)</li>
                <li>Rechtsvertreter, Gerichte im Anlassfall</li>
                <li>Wirtschaftstreuhänder (für Zwecke des Auditing)</li>
                <li>Zuständige Verwaltungsbehörden, insb. Finanzbehörden</li>
                <li>
                  Inkassounternehmen zur Schuldeneintreibung (ins Ausland daher
                  nur, soweit die Schuld im Ausland eingetrieben werden muss)
                </li>
                <li>
                  Fremdfinanzierer wie Leasing- oder Factoringunternehmen und
                  Zessionare, sofern die Lieferung oder Leistung auf diese Weise
                  fremdfinanziert ist
                </li>
                <li>
                  Vertrags- oder Geschäftspartner, die an der Lieferung oder
                  Leistung mitwirken bzw. mitwirken sollen (z.B. Zustelldienste)
                </li>
                <li>
                  Versicherungen aus Anlass des Abschlusses eines
                  Versicherungsvertrages über die Lieferung/Leistung oder des
                  Eintritts des Versicherungsfalles
                </li>
                <li>
                  Behörden und Institutionen für die Erstellung der gesetzlich
                  vorgeschriebenen (amtlichen) Statistiken
                </li>
                <li>Kunden (Empfänger von Leistungen)</li>
                <li>Wirtschaftsauskunfteien</li>
              </ul>
              <p>
                Eine Übermittlung an Drittländer oder internationale
                Organisationen erfolgt in diesen Fällen nur, wenn entweder ein
                Angemessenheitsbeschluss nach Art. 45 DSGVO vorliegt oder die
                Voraussetzungen des Art. 49 Abs. 1 lit. b, c oder e DSGVO
                vorliegen (Erforderlichkeit zur Durchführung von
                vorvertraglichen Maßnahmen auf Antrag der betroffenen Person
                oder in ihrem Interesse, Geltendmachung, Ausübung oder
                Verteidigung von Rechtsansprüchen) oder eine Einwilligung gemäß
                Art. 49 Abs. 1 lit. a DSGVO vorliegt.
              </p>
              <p>&nbsp;</p>
              <h3>Erhebung von Daten aus anderen Quellen</h3>
              <p>
                Herkunft (Quellen) und&nbsp; Kategorien personenbezogener Daten:
              </p>
              <p>
                Wir erheben bei entsprechendem berechtigtem Interesse (Höhe der
                Auftragssumme) die Bewertung Ihrer Bonität vor Annahme eines
                Auftrags bei Wirtschaftsauskunfteien.
              </p>
              <p>&nbsp;</p>
              <h3>Speicherdauer</h3>
              <p>
                Bis zur Beendigung der Geschäftsbeziehung oder bis zum Ablauf
                der für den Verantwortlichen geltenden Garantie-,
                Gewährleistungs-, Verjährungs- und gesetzlichen
                Aufbewahrungsfristen; darüber hinaus bis zur Beendigung von
                allfälligen Rechtsstreitigkeiten, bei denen die Daten als Beweis
                benötigt werden. Insbesondere mind. 7 Jahre zum Nachweis der
                Erfüllung steuer- und abgabenrechtlicher Pflichten (BAO), mind.
                22 Jahre für Immobiliengeschäfte. Bei Leistungen, bei denen
                Schadenersatzforderungen gemäß ABGB oder aus anderen Titeln
                (z.B. Produkthaftung) geltend gemacht werden können, für die
                erforderliche Dauer.
              </p>
              <p>&nbsp;</p>
              <h3>Bereitstellung von Daten</h3>
              <p>
                Die Erhebung der von uns zwingend verlangten Daten ist für einen
                Vertragsabschluss erforderlich.
              </p>
              <p>&nbsp;</p>
              <h3>Weiterverarbeitung Ihrer Daten zu anderen Zwecken</h3>
              <p>
                Ein Teil der erhobenen Daten wird für Marketingmaßnahmen
                weiterverarbeitet. Darauf und auf Ihre Widerspruchsrechte weisen
                wir bereits vor der Erhebung hin. Wir bitten Sie, diesbezüglich
                Punkt 3 der Datenschutzinformation zu beachten.
              </p>
              <p>&nbsp;</p>
            </>
          }
          isActive={activeIndex === 1}
          onToggle={() => handleToggle(1)}
        />
        <AccordionItem
          title="3. Verarbeitungen für Marketing (Kundenanfragen, Kundenbetreuung und Werbung)"
          content={
            <>
              <h3>Zwecke der Verarbeitung</h3>
              <p>
                Kundenbetreuung und Marketing (Direktwerbung) für Zwecke des
                Verantwortlichen, auch durch entsprechende Newsletter und
                Beantwortung von Kundenanfragen, Versand von Werbung im Postweg
                oder per Mail.
              </p>
              <p>&nbsp;</p>
              <h3>Rechtsgrundlagen und berechtigte Interessen</h3>
              <p>
                Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO und Art. 49 Abs. 1
                lit a DSGVO für die Webanalyse mit Google Analytics zur
                Verbesserung und Optimierung unseres Online-Auftritts sowie für
                die „Konversionsmessung“ mittels Besucheraktions-Pixel von
                LinkedIn.
              </p>
              <p>
                Berechtigte Interessen auf Grundlage des Art. 6 Abs. 1 lit. f
                DSGVO:
              </p>
              <ul>
                <li>Werbung für Waren und Dienstleistungen des Unternehmens</li>
                <li>
                  Kundengewinnung und Kundenbindung (Bonusprogramme für
                  Stammkunden)
                </li>
                <li>
                  Verbesserung der Attraktivität der Angebote und des
                  Web-Auftritts
                </li>
              </ul>
              <p>&nbsp;</p>
              <h3>Widerspruch/Widerruf</h3>
              <p>
                Sie haben ein jederzeitiges formloses Widerspruchsrecht gegen
                die Verarbeitung zu diesen Zwecken und können die Löschung oder
                die Einschränkung der Verarbeitung verlangen. Im Falle Ihrer
                Einwilligung haben Sie ein jederzeitiges Widerrufsrecht mit
                Wirkung ab diesem Zeitpunkt. Wir weisen auf Ihre Rechte vor der
                Direkterhebung der Daten hin.
              </p>
              {/*<p>
                Sie können Einwilligung in die Verarbeitung durch Google
                Analytics und das LinkedIn Pixel widerrufen, indem Sie die
                Cookie-Einstellungen durch Klick auf den Button unten bei Punkt
                10 öffnen.
              </p>*/}
              <p>&nbsp;</p>
              <h3>
                Empfänger oder Kategorien von Empfängern und Übermittlungen in
                Drittländer oder an internationale Organisationen
              </h3>
              <p>
                Bei Notwendigkeit erfolgen Übermittlungen personenbezogener
                Daten an folgende Kategorien von Empfängern. Wir verwenden die
                Dienste folgender Auftragsverarbeiter: Derzeit Keine.
              </p>
              {/*<ul>
                <li>Druckereien</li>
                <li>Zustelldienste</li>
                <li>Adressdatencheck</li>
                {/*<li>Sendinblue GmbH (Newsletterversand mittels Brevo)</li>
              </ul>*/}
              {/*<h3>Erhebung von Daten aus anderen Quellen</h3>
              <p>Herkunft (Quellen) und Kategorien personenbezogener Daten:</p>
              <p>Siehe unten den Punkt „Cookies“.</p>
              <p>&nbsp;</p>
              <h3>Cookies</h3>
              <p>
                <u>Google Analytics:</u>
              </p>
              <p>
                Mit Ihrer Erlaubnis für Google Analytics helfen Sie uns, mehr
                über die Nutzung unserer Webseite zu erfahren und sie zu
                verbessern. Wir erfahren, welche Website vor dem Zugriff auf
                unsere Website besucht wurde und wie unsere Website genutzt
                wurde.
              </p>
              <p>
                Es werden folgende Kategorien personenbezogener Daten erhoben
                und verarbeitet:
              </p>
              <ul>
                <li>pseudonymisierte IP-Adresse</li>
                <li>Datum und Uhrzeit des Besuchs</li>
                <li>Herkunft (Referrer URL)</li>
                <li>Klickpfad</li>
                <li>Browser Informationen</li>
                <li>Geräte Informationen</li>
                <li>JavaScript-Support</li>
                <li>Besuchte Seiten</li>
                <li>Downloads</li>
                <li>Flash-Version</li>
                <li>Standortinformationen</li>
                <li>Widget-Interaktionen</li>
              </ul>
              <p>
                Wir setzen Google Analytics ausschließlich mit aktivierter
                IP-Pseudonymisierung ein. Das bedeutet, das Pseudonym lässt
                keine direkten Rückschlüsse auf Sie als User zu.
              </p>
              <p>&nbsp;</p>
              <p>
                Für die Verarbeitung dieser Daten besteht gemeinsame
                Verantwortlichkeit gemäß Art. 26 DSGVO mit Google Ireland
                Limited:
                <br /> Google Ireland Limited
                <br /> nach irischem Recht eingetragen und betrieben
                (Registernummer: 368047)
                <br /> Gordon House, Barrow Street, Dublin 4, Irland
              </p>
              <p>&nbsp;</p>
              <p>
                Die Bedingungen der dafür erforderlichen Vereinbarung mit Google
                finden Sie hier:
              </p>
              <p>
                <a
                  href="https://support.google.com/analytics/answer/9012600"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://support.google.com/analytics/answer/9012600
                </a>
              </p>
              <p>&nbsp;</p>
              <p>
                Die Datennutzung durch Google finden Sie hier beschrieben:{" "}
                <a
                  href="https://policies.google.com/technologies/partner-sites"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://policies.google.com/technologies/partner-sites
                </a>
              </p>
              <p>&nbsp;</p>
              <h3>Weitergabe an Drittländer</h3>
              <p className="Standard1">
                Der Service Google Analytics kann Daten weltweit in Drittländern
                außerhalb der Europäischen Union und des europäischen
                Wirtschaftsraums verarbeiten. Falls die Daten in Drittländer wie
                die USA übertragen werden, besteht das Risiko, dass diese von
                dortigen Behörden zu Kontroll- und Überwachungszwecken
                verarbeitet werden können, ohne dass Ihnen angemessene
                Rechtsbehelfsmöglichkeiten zustehen.
              </p>
              <p className="Standard1">&nbsp;</p>
              <p>
                Google verarbeitet die Daten auch für (beliebige) eigene Zwecke
                gemäß dessen Datenschutzrichtlinien:
              </p>
              <p>
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://policies.google.com/privacy
                </a>
              </p>
              <p>
                <u>
                  Damit verbunden ist u.a. Profilbildung und die Verbindung der
                  Daten mit einem Google Account, den Sie nutzen, oder
                  Nutzungsdaten anderer Geräte, Suchverläufen etc.
                </u>
              </p>
              <p className="Standard1">
                Cookie Richtlinie:{" "}
                <a
                  href="https://policies.google.com/technologies/cookies?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://policies.google.com/technologies/cookies?hl=en
                </a>
              </p>
              <p>
                Für diese Verarbeitungen ist Google Ireland Limited (mit den
                konzernverbundenen Unternehmen Alphabet, Inc., 1600 und Google
                LLC, beide 1600 Amphitheatre Parkway Mountain View, CA 94043,
                USA, und deren Tochterunternehmen) allein verantwortlich.
              </p>
              <p>&nbsp;</p>
              <p>
                Sie können die Verarbeitung widerrufen, indem Sie die
                Cookie-Einstellungen durch Klick auf den{" "}
                {/*<a href="#">Button am Ende dieser Seite</a> öffnen.
              </p>
              <p>&nbsp;</p>
              <p>
                <u>Facebook Pixel:</u>
              </p>
              <p>
                Damit kann das Verhalten der Seitenbesucher nachverfolgt werden,
                nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
                Website des Anbieters weitergeleitet wurden. Dadurch können die
                Wirksamkeit der Facebook-Werbeanzeigen für statistische und
                Marktforschungszwecke ausgewertet werden und zukünftige
                Werbemaßnahmen optimiert werden. Die erhobenen Daten sind für
                uns als Betreiber dieser Website anonym, wir können keine
                Rückschlüsse auf die Identität der Nutzer ziehen. Wir sind nur
                gemeinsam Verantwortliche, soweit wir Einfluss auf die
                Verarbeitung Ihrer Daten durch Facebook nehmen können.
              </p>
              <p>
                Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
                Daten auf unserer Website erfasst und an Facebook weitergeleitet
                werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für
                diese Datenverarbeitung verantwortlich (Art. 26 DSGVO).
              </p>
              <p>
                Die gemeinsame Verantwortlichkeit beschränkt sich dabei
                ausschließlich auf die Erfassung der Daten und deren Weitergabe
                an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung
                durch Facebook ist nicht Teil der gemeinsamen Verantwortung. Die
                uns gemeinsam obliegenden Verpflichtungen wurden in einer
                Vereinbarung über gemeinsame Verarbeitung festgehalten. Den
                Wortlaut der Vereinbarung finden Sie unter:{" "}
                <a
                  href="https://www.facebook.com/legal/controller_addendum"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/legal/controller_addendum
                </a>
                . Laut dieser Vereinbarung sind wir für die Erteilung der
                Datenschutzinformationen beim Einsatz des Facebook-Tools und für
                die datenschutzrechtlich sichere Implementierung des Tools auf
                unserer Website verantwortlich.
              </p>
              <p>
                Die Daten werden aber von Facebook für eigene Zwecke gespeichert
                und verarbeitet, sodass eine Verbindung zum jeweiligen
                Nutzerprofil möglich ist und Facebook die Daten für eigene
                Werbezwecke, entsprechend der
                Facebook-Datenverwendungsrichtlinie verwenden kann. Dadurch kann
                Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook
                sowie außerhalb von Facebook ermöglichen. Diese Verwendung der
                Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.
              </p>
              <p>
                Für die Datensicherheit der Facebook-Produkte ist Facebook
                verantwortlich. Betroffenenrechte (z.B. Auskunftsersuchen)
                hinsichtlich der bei Facebook verarbeiteten Daten können Sie
                direkt bei Facebook geltend machen. Wenn Sie die
                Betroffenenrechte bei uns geltend machen, sind wir verpflichtet,
                diese an Facebook weiterzuleiten.
              </p>
              <p>
                Für jene Verarbeitungen, für die Facebook allein verantwortlich
                ist, verweisen wir auf die Datenschutzrichtlinie des
                Unternehmens:{" "}
                <a
                  href="https://www.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/about/privacy/
                </a>
                . In den Datenschutzhinweisen von Facebook finden Sie weitere
                Hinweise zum Schutz Ihrer Privatsphäre:{" "}
                <a
                  href="https://de-de.facebook.com/about/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://de-de.facebook.com/about/privacy/
                </a>
                . Sie können außerdem die Remarketing-Funktion „Custom
                Audiences“ im Bereich Einstellungen für Werbeanzeigen unter{" "}
                <a
                  href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
                </a>{" "}
                deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein. Wenn
                Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
                Werbung von Facebook auf der Website der European Interactive
                Digital Advertising Alliance deaktivieren:{" "}
                <a
                  href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  http://www.youronlinechoices.com/de/praferenzmanagement/
                </a>
                .
              </p>
              <p>&nbsp;</p>
              <h3>Weitergabe an Drittländer</h3>
              <p>
                Facebook verarbeitet die mit dem Facebook Pixel erhobenen Daten
                weltweit in Drittländern außerhalb der Europäischen Union und
                des europäischen Wirtschaftsraums. Falls die Daten in
                Drittländer wie die USA übertragen werden, besteht das Risiko,
                dass diese von dortigen Behörden zu Kontroll- und
                Überwachungszwecken verarbeitet werden können, ohne dass Ihnen
                angemessene Rechtsbehelfsmöglichkeiten zustehen.
              </p>
              <p>
                Die Datenübertragung in die USA wird von Facebook auf die
                Standardvertragsklauseln der EU-Kommission gestützt.
              </p>
              <p>
                Sie können die Verarbeitung widerrufen, indem Sie die
                Cookie-Einstellungen durch Klick auf den{" "}
                <a
                  href="https://www.wuerth-hochenburger.at/datenschutz/#c619"
                  target="_blank"
                  rel="noreferrer"
                >
                  Button am Ende dieser Seite
                </a>{" "}
                öffnen.
                </p>*/}
              <p>&nbsp;</p>
              <h3>Speicherdauer</h3>
              <p>
                Interessentendaten werden für 3 Jahre ab dem letzten
                Kundenkontakt gespeichert. Postanschriften und E-Mail-Adressen
                werden für die Dauer werblicher Maßnahmen oder bis zum
                Widerspruch bzw. bis zur Abmeldung eines Newsletters
                gespeichert; darüber hinaus erfolgt eine Speicherung, wenn die
                Einschränkung der Verarbeitung verlangt wird oder die
                Verarbeitung für andere Zwecke eine längere Speicherdauer
                erfordert (insbesondere gemäß Punkt 2 der
                Datenschutzinformation).
              </p>
              {/*<p>
                Die Daten in Google Analytics werden je nach Kategorie zwischen
                6 Monaten und 2 Jahren gespeichert.
              </p>
              <p>
                Die Daten des Facebook Pixels werden von Facebook 2 Jahre
                gespeichert.
                </p>*/}
              <p>&nbsp;</p>
              <h3>Bereitstellung von Daten</h3>
              <p>
                Es besteht keine Verpflichtung zur Bereitstellung von Daten.
              </p>
              <p>&nbsp;</p>
              <h3>Weiterverarbeitung Ihrer Daten zu anderen Zwecken</h3>
              <p>
                Bei Kundenanfragen erfolgt abhängig von der Art der Anfrage und
                der Durchführung der Bearbeitung ggf. eine Weiterverarbeitung im
                Zusammenhang mit Lieferungen und Leistungen, siehe dazu Näheres
                bei Punkt 2 der Datenschutzinformation.
              </p>
              <p>&nbsp;</p>
            </>
          }
          isActive={activeIndex === 2}
          onToggle={() => handleToggle(2)}
        />
        <AccordionItem
          title="4. Kommunikation"
          content={
            <>
              <h3>Zwecke der Verarbeitung</h3>
              <p>
                Wenn Sie per Formular auf der Website, per E-Mail oder
                telefonisch Kontakt mit uns aufnehmen, werden die für die
                Kommunikation technisch notwendigen Daten (je nach gewähltem
                Kommunikationsmittel) erhoben und verarbeitet, wie z.B.
                Telefonnummer, E-Mail-Adresse, Zeitpunkt (ggf. Dauer) des
                Kommunikationsvorganges etc. Eingehende E-Mails werden dabei
                auch mittels Spamfilter, Firewall, Virenscanner etc. zu Zwecken
                gemäß Punkt 1 dieser Datenschutzinformation verarbeitet.
              </p>
              <p>&nbsp;</p>
              <h3>Rechtsgrundlagen und berechtigte Interessen</h3>
              <p>
                Ihre angegebenen Daten werden zwecks Bearbeitung der Anfrage auf
                Basis des entsprechenden berechtigten Interesses gemäß Art. 6
                Abs. 1 lit. f DSGVO verarbeitet. Die weiteren Zwecke der
                Verarbeitung sind vom Inhalt der Kommunikation abhängig und sind
                bei den Punkten 1-5 in dieser Datenschutzinformation jeweils
                angegeben.
              </p>
              <p>&nbsp;</p>
              <h3>Widerspruch</h3>
              <p>
                Sie haben bei Verarbeitungen zu Zwecken gemäß Art. 6 Abs. 1 lit.
                e und f DSGVO in dieser Datenschutzinformation soweit dort
                angegeben ein jederzeitiges formloses Widerspruchsrecht gegen
                die Verarbeitung zu diesen Zwecken aus Gründen, die sich aus
                Ihrer besonderen Situation ergeben, und können die Löschung oder
                die Einschränkung der Verarbeitung verlangen. Wir weisen auf
                Ihre Rechte vor der Direkterhebung der Daten (z.B. in
                Online-Formularen) hin. Dieses Recht besteht nicht, soweit wir
                Ihre Anfrage oder einen Kommunikationsvorgang aus anderen
                Gründen (z.B. vertragliche Verpflichtungen, gesetzliche
                Verpflichtungen, Produkthaftung) verarbeiten und für den in
                dieser Datenschutzinformation jeweils angegebenen Zeitraum
                speichern müssen.
              </p>
              <p>&nbsp;</p>
              <h3>
                Empfänger oder Kategorien von Empfängern und Übermittlungen in
                Drittländer oder an internationale Organisationen
              </h3>
              <p>
                Abhängig von den Zwecken eines Kommunikationsvorganges kann eine
                entsprechende Übermittlung gemäß Punkt 1-5 dieser
                Datenschutzinformation erforderlich sein.
              </p>
              <p>&nbsp;</p>
              <h3>
                Erhebung von Daten aus anderen Quellen: Kategorien
                personenbezogener Daten
              </h3>
              <p>
                Bei jeder Kontaktaufnahme erfassen unsere Systeme automatisiert
                Daten und Informationen von den Kommunikationsmitteln bzw.
                technischen Systemen der Betroffenen. Folgende Daten werden
                hierbei erhoben: Kontaktdaten des Betroffenen (z.B.
                Telefonnummer, E-Mail-Adresse etc.), Zeitpunkt des
                Kommunikationsvorganges (Datum, Uhrzeit), Einwilligung in die
                Aufzeichnung, ggf. Gesprächsdauer, ggf. Sprachnachricht
                (Anrufbeantworter), ggf. Kommunikationsinhalte (Textnachricht).
              </p>
              <p>&nbsp;</p>
              <h3>Speicherdauer</h3>
              <p>
                Die Speicherdauer richtet sich nach dem erforderlichen Zeitraum
                für die Durchführung des Kommunikationsvorgangs bis zum
                Abschluss; darüber hinaus kann abhängig vom Zweck des
                Kommunikationsvorganges eine längere Speicherung erforderlich
                sein, die jeweils bei Punkt 1-5 dieser Datenschutzinformation
                angegeben ist.
              </p>
              <p>&nbsp;</p>
              <h3>Bereitstellung von Daten</h3>
              <p>Es besteht keine Pflicht zur Bereitstellung der Daten.</p>
              <p>&nbsp;</p>
              <h3>Weiterverarbeitung Ihrer Daten zu anderen Zwecken</h3>
              <p>
                Es erfolgt abhängig von den Inhalten Ihrer Anfrage oder des
                Kommunikationsvorganges eine Weiterverarbeitung zu allen in
                dieser Datenschutzinformation bei Punkt 1-5 aufgelisteten
                Zwecken.
              </p>
              <p>&nbsp;</p>
            </>
          }
          isActive={activeIndex === 3}
          onToggle={() => handleToggle(3)}
        />
        <AccordionItem
          title="5. Betroffenenrechte, Widerspruch, Widerruf und Beschwerderecht"
          content={
            <>
              <p>
                Sie können die nachfolgend aufgezählten Betroffenenrechte
                geltend machen, wenn die jeweiligen Voraussetzungen gegeben
                sind. Bitte beachten Sie, dass Sie gegebenenfalls vorab einen
                geeigneten Identitätsnachweis erbringen müssen.
              </p>
              <ul>
                <li>
                  Recht auf Auskunft: Sie können – im Regelfall kostenlos – eine
                  Bestätigung darüber verlangen, ob personenbezogene Daten von
                  Ihnen verarbeitet werden, und Auskunft über diese Daten und
                  die Informationen gemäß Art. 15 DSGVO verlangen.
                </li>
                <li>
                  Recht auf Berichtigung, wenn wir unrichtige oder
                  unvollständige Daten über Sie verarbeiten (Art. 16 DSGVO).
                </li>
                <li>
                  Recht auf Löschung Sie betreffender personenbezogener Daten,
                  wenn die Voraussetzungen des Art. 17 DSGVO vorliegen.
                </li>
                <li>
                  Recht auf Einschränkung der Verarbeitung Ihrer Daten gemäß
                  Art. 18 DSGVO.
                </li>
                <li>
                  Recht auf Datenübertragbarkeit und Erhalt der
                  personenbezogenen Daten, die Sie in einem automatisierten
                  Verfahren bereitgestellt haben, in einem strukturierten,
                  gängigen und maschinenlesbaren Format (Art. 20 DSGVO).
                </li>
                <li>
                  Bei Verarbeitungen, die auf ein (nicht überwiegendes)
                  berechtigtes Interesse gestützt werden, haben Sie das
                  jederzeitige Recht auf Widerspruch gemäß Art. 21 DSGVO. Bei
                  Verarbeitungen zum Zweck der Direktwerbung und damit in
                  Verbindung stehendem Profiling besteht dieses Recht ohne
                  Einschränkungen.
                </li>
              </ul>
              <p>
                &nbsp;
                <br /> Sie können erteilte Einwilligungen in die Verarbeitung
                personenbezogener Daten jederzeit widerrufen; die Rechtmäßigkeit
                der bis zum Zeitpunkt des Widerrufs erfolgten Verarbeitung wird
                davon nicht berührt.
              </p>
              <p>&nbsp;</p>
              <h3>Beschwerderecht</h3>
              <p>
                Sie haben das Recht auf Beschwerde bei einer für Sie zuständigen
                Aufsichtsbehörde (Datenschutzbehörde), insbesondere im
                EU-Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes
                oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht
                sind, dass die Verarbeitung der Sie betreffenden
                personenbezogenen Daten gegen die Datenschutzgrundverordnung
                verstößt oder Ihre Betroffenenrechte verletzt wurden. Wir würden
                begrüßen, wenn Sie vorher Kontakt mit uns aufnehmen und
                Gelegenheit zur Stellungnahme geben.
                <br /> Eine Liste der Aufsichtsbehörden finden Sie hier:&nbsp;
                <a
                  href="https://edpb.europa.eu/about-edpb/board/members_en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://edpb.europa.eu/about-edpb/board/members_en
                </a>
                .
              </p>
              <p>&nbsp;</p>
            </>
          }
          isActive={activeIndex === 4}
          onToggle={() => handleToggle(4)}
        />
      </div>
    </div>
  );
};

export default GDPR;
