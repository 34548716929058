import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { localStorageGet } from "./utils/LocalStorageHelper";

const defaultLanguage = localStorageGet("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["de", "en"],
    lng: defaultLanguage || "de",
    fallbackLng: "en",
    react: {
      useSuspense: false,
    },
    resources: {
      de: {
        translation: {
          // IDEA //
          Idea: "Idee",
          Product: "Produkt",
          OurJourney: "Unsere Reise",
          Team: "Team",
          Contact: "Kontakt",
          en: "EN",
          de: "DE",

          IdeaH: "IDEE",
          AIToolForPMH: "AI TOOL FÜR PREDICTIVE MAINTANCE",
          AIToolForPMShort: "Produktbeschreibung",
          SloganShort: "Einfache Lösungen für komplexe Probleme",
          ContactUsH: "KONTAKTIEREN SIE UNS",
          Vision: "Vision",
          VisionH: "VISION",
          VisionShort:
            "Unsere Vision ist, die Fertigungsindustrie mit nutzerzentrierten KI-Tools zu transformieren.",
          Mission: "Mission",
          MissionH: "MISSION",
          MissionShort:
            "Unsere Mission ist, einen breiten Zugang zu KI in der Fertigungsindustrie zu ermöglichen, indem wir intuitive und skalierbare KI-Tools bereitstellen, die Innovation, Effizienz und Wettbewerbsvorteile fördern.",
          IdeaTitle1:
            "Automatisierte Qualitätskontrollen und Visualisierungen von Datensätzen",
          IdeaContent1:
            "Der Benutzer kann den Maschinendatensatz in einer csv-Datei hochladen und unser Tool führt automatisch eine Reihe von Qualitätsprüfungen durch, um die Brauchbarkeit für die automatische Modellentwicklung zu gewährleisten. Die Ergebnisse dieser Qualitätsprüfungen werden dem Benutzer entsprechend visualisiert.",
          IdeaTitle2: "Automatisierte Modellerstellung für Anomalieerkennung",
          IdeaContent2:
            "Im nächsten Schritt kann der Benutzer mit dem hochgeladenen Datensatz eine eigene KI ohne Vorkenntnisse in Data Science entwickeln. Das Tool wählt automatisch das beste Modell aus und visualisiert die erkannten Anomalien.",
          IdeaTitle3:
            "Echtzeit-Anomalieerkennung mit Benutzerfeedback zur kontinuierlichen Verbesserung",
          IdeaContent3:
            "Nachdem die KI erfolgreich auf dem offline Datensatz entwickelt wurde, kann der Nutzer seine Online-Maschinendaten über flexible Schnittstellen mit unserem Tool verbinden und eine Echtzeit-Anomalieerkennung implementieren. Unser Tool benachrichtigt die Nutzer bei jedem erkannten Anomalie-Ereignis. Um sicherzustellen, dass das Modell Änderungen im Prozess oder in der Maschine verstehen kann, bietet unser Tool eine Benutzer-Feedback-Funktion, um die Relevanz der erkannten anomalen Ereignisse zu bestätigen.",
          IdeaTitle4: "Automatische Fehlerdiagnose für Predictive Maintenance",
          IdeaContent4:
            "Die erkannten Anomalien werden gespeichert und von unserer KI automatisch geclustert. Durch die Visualisierung der Anomalie-Cluster in einem Pareto-Diagramm können die Benutzer diese Informationen nutzen, um Maßnahmen zur Reduzierung ungeplanter Maschinenstillstände zu definieren. Unser Team unterstützt Sie bei der Prozessanalyse und bei der Implementierung zusätzlicher Sensoren, um damit die Vorhersage spezifischer Anomalien zu ermöglichen.",

          // ROADMAP //
          RoadmapH: "UNSERE REISE",
          RoadmapSloganH: "KURZ GESAGT",
          RoadmapSloganContent:
            "Unser Ziel ist es, eine nutzerzentrierte KI-Plattform für die Fertigungsindustrie zu entwickeln. Dabei beginnen wir mit einem skalierbaren und nutzerzentrierten KI-Tool, das eine skalierbare und strukturierte Implementierung von Predictive Maintenance (PdM) ermöglicht.",
          ProblemStatement: "Problemstellung",
          RoadmapProblemStatementContent:
            "Die Zunahme an Sensor- und Prozessdaten stellt TechnikerInnen vor die Herausforderung, komplexe Informationen maschinenübergreifend zu verwalten. Studien zeigen, dass erfolgreiche Predictive-Maintenance-Lösungen die Ausfallzeiten von Maschinen um bis zu 18% reduzieren, die Produktionskosten um bis zu 20% senken und den Umsatz um bis zu 10% steigern können. Allerdings fehlt den TechnikerInnen oft die KI-Expertise und Ressourcen, um diese Lösungen zu implementieren.",
          Solution: "Lösung",
          RoadmapSolutionContent:
            "Unsere nutzerzentrierte und skalierbare Lösung ermöglicht es TechnikerInnen, Predictive Maintenance ohne KI-Kenntnisse zu implementieren. Mit unserer intuitiven Benutzeroberfläche ermöglichen wir unseren Kunden, KI für die Erkennung und Diagnose von Anomalien in Echtzeit mit nur wenigen Klicks zu entwickeln und einzusetzen. Die TechnikerInnen können damit die richtigen Maßnahmen definieren und die Ausfallzeiten der Maschinen erheblich reduzieren, um das volle Potenzial von Predictive Maintenance auszuschöpfen.",
          POC: "Proof Of Concept",
          RoadmapPOCContent:
            "Das Design und das Konzept unseres Tools ist bereits end-to-end fertig. Die KI-Engine, die unser Tool antreibt, wurde im Rahmen eines Proof of Concept mit Open-Source-Datensätzen validiert.",
          BusinessPlan: "Businessplan",
          RoadmapBusinessPlanContent:
            "Wir haben einen umfassenden Businessplan erstellt, in dem unsere Strategie, unsere Ziele und unsere Finanzprognosen dargelegt sind. Dies dient als unser Fahrplan und kann auf Anfrage präsentiert werden.",
          SeedInvestment: "Pre-Seed Investition",
          RoadmapSeedInvestmentContent:
            "Wir sind derzeit auf der Suche nach Pre-Seed-Investitionen zur Finanzierung unserer ersten Aktivitäten. Mit dieser Investition wollen wir unseren nächsten Meilenstein mit den notwendigen Ressourcen erreichen und die Zeit bis zur Markteinführung unseres ersten Tools verkürzen.",
          EarlyAdopter: "Early Adopter",
          RoadmapEarlyAdopterContent:
            "Wir sind auf der Suche nach Early Adopters, die bereit sind, das volle Potenzial von Predictive Maintenance mit unserem Tool auszuschöpfen und ihre Wettbewerbsfähigkeit zu steigern. Sie sind bereit, uns bei der Validierung unseres Produkts mit ihren Echtzeit-Maschinendaten zu unterstützen. Darüber hinaus können Early Adopters von Finanzierungsmöglichkeiten mit unserer Lösung profitieren, da ihre Prozesse digitalisiert und optimiert werden.",

          // TEAM //
          IntroducingMembers: "Lernen Sie unsere wichtigen Mitglieder kennen",
          MeetOurTeamH: "LERNEN SIE UNSER TEAM KENNEN",
          InterestedInJoining: "Interessiert, unserem Team beizutreten?",
          SenUsCV:
            "Senden Sie uns Ihren Lebenslauf und wir werden uns bei Ihnen melden.",
          SendCVH: "LEBENSLAUF SENDEN",
          DaliRole: "AI-Entwickler & Geschäftsführer",
          MilanRole: "Frontend-Entwickler & Gesellschafter",
          IlijaRole: "Backend-Entwickler & Gesellschafter",
          DaliInfo:
            "Mit über 8 Jahren Erfahrung in der pharmazeutischen Produktion war Dalibor als Ingenieur für mehrere technische Produktionsanlagen verantwortlich und führte viele Projekte mit lokalen und globalen Projektteams zum Erfolg. Während seines berufsbegleitenden Studiums entwickelte er tiefgreifende Kenntnisse im Bereich KI/ML und entwickelte die KI-Engine, die unser Tool antreibt.",
          MilanInfo:
            "Ein Frontend-Entwickler mit über 6 Jahren Erfahrung in der Bauindustrie, Milan ist spezialisiert auf UI-Entwicklung und ERP-Beratung. Er unterstützt das Team mit seiner Frontend-Expertise im No-Code-KI-Tool für Predictive Maintenance.",
          IlijaInfo:
            "Ein Java-Softwareentwickler mit über 6 Jahren Erfahrung in verschiedenen Branchen, Ilija ist auf Cybersicherheit, Unix-Sicherheit und Computer Vision spezialisiert. Er trat dem Team wegen seiner Backend- und Software-Deployment-Kompetenzen bei.",

          // CONTACT //
          SendUaMessageH: "SCHICKEN SIE UNS EINE NACHRICHT",
          FullName: "Vollständiger Name",
          CompanyName: "Firmenname",
          Email: "E-Mail",
          Phone: "Telefon",
          Message: "Nachricht",
          SendH: "SENDEN",
          Optional: "Optional",

          // PARTNERS //
          Patrners: "Patrners",
          MeetOurPartnersH: "UNSERE PARTNER",
        },
      },
      en: {
        translation: {
          // IDEA //
          Idea: "Idea",
          Product: "Product",
          OurJourney: "Our Journey",
          Team: "Team",
          Contact: "Contact",
          en: "EN",
          de: "DE",

          IdeaH: "IDEA",
          AIToolForPMH: "AI TOOL FOR PREDICTIVE MAINTENCE",
          AIToolForPMShort: "Product Description",
          SloganShort: "Simple solutions for complex problems",
          ContactUsH: "CONTACT US",
          Vision: "Vision",
          VisionH: "VISION",
          VisionShort:
            "Our vision is to transform the manufacturing industry with human-centric AI tools",
          Mission: "Mission",
          MissionH: "MISSION",
          MissionShort:
            "Our mission is to enable broad access to AI in the manufacturing industry by delivering intuitive and scalable AI tools that drive innovation, efficiency and competitive advantage",
          IdeaTitle1: "Automated Dataset Quality Checks and Visualization",
          IdeaContent1:
            "The user can upload the machine dataset in a .csv file and the tool will automatically perform a series of quality checks to ensure the usability for the automatic model development. The results of these quality checks are visualized and presented to the user, allowing for easy assessment and necessary adjustments before proceeding to model development.",
          IdeaTitle2: "Automated Model Development for Anomaly Detection",
          IdeaContent2:
            "In the next step, the user can develop a new AI using the uploaded dataset without any Data Science background. The tool automatically selects the best model and visualizes the detected anomalies.",
          IdeaTitle3:
            "Real-Time Anomaly Detection with User Feedback for Continuous Improvement",
          IdeaContent3:
            "After the AI has been successfully developed offline on the dataset, the user can connect their online machine data via flexible interfaces with our tool and run a real-time anomaly detection. Our tool notifies the uses with each detected anomaly event. To ensure that the model can understand changes in the process or machine, our tool provides a human feedback feature, where the user can aknowledged the detected anomaly events based on the relevancy.",
          IdeaTitle4: "Automatic Fault Diagnosis for Predictive Maintenance",
          IdeaContent4:
            "The aknowledged anomaly events are stored and automatically clustered by our AI. By visualizing the anomaly clusters in a Pareto chart users can utilize this information to increase the know-how and define actions for reduction of unplanned machine downtime. Our team can provide additional support for process analysis and during implementation of additional sensors, which enables the prediction of specific anomalies and therefore a systematic approach to Predictive Maintenance.",

          // ROADMAP //
          RoadmapH: "OUR JOURNEY",
          RoadmapSloganH: "IN A NUTSHELL",
          RoadmapSloganContent:
            "Our goal is to develop a useful AI platform for the manufacturing industry, starting with a scalable and human-centric AI tool that enables a structured implementation of Predictive Maintenance (PdM).",
          ProblemStatement: "Problem Statement",
          RoadmapProblemStatementContent:
            "The increase in sensor and process data is making it challenging for engineers to manage complex information across machines. Studies show that successful Predictive Maintenance solutions can reduce machine downtime by up to 18%, cut production costs by up to 20%, and increase turnover by up to 10%. However, engineers often lack the AI expertise and resources to implement these solutions.",
          Solution: "Solution",
          RoadmapSolutionContent:
            "Our human-centric and scalable solution allows engineers to implement Predictive Maintenance without AI expertise. With our intuitive UI, we are enabling engineers to develop and deploy AI for real-time anomaly detection and diagnosis with just a few clicks. The engineers can quickly understand machine errors, define the right actions, and significantly reduce machine downtime, unlocking the full potential of Predictive Maintenance.",
          POC: "Proof of Concept",
          RoadmapPOCContent:
            "The design and concept of our tool are already built end-to-end. The AI engine powering our tool has been validated during a proof of concept using open-source datasets.",
          BusinessPlan: "Business Plan",
          RoadmapBusinessPlanContent:
            "We have created a comprehensive business plan outlining our strategy, goals, and financial projections. This plan serves as our roadmap and can be shared on request.",
          SeedInvestment: "Pre-Seed Investment",
          RoadmapSeedInvestmentContent:
            "We are currently seeking for pre-seed investments to fund our initial operations. This investment helps us to start our business with necessary resources and increase time to market of our first tool.",
          EarlyAdopter: "Early Adopter",
          RoadmapEarlyAdopterContent:
            "We are searching for early adopters who are ready to unlock the full potential of successful Predictive Maintenance implementation with our tool and increase their market competitiveness. These early adopters are willing to support us in validating our product with their real-time industry data. Additionally, early adopters can benefit from funding opportunities with our solution, as we help digitalize and optimize their processes.",

          // TEAM //
          IntroducingMembers: "Introducing our key members",
          MeetOurTeamH: "MEET OUR TEAM",
          InterestedInJoining: "Are you interested in joining our team?",
          SenUsCV: "Send us your CV and we will contact you.",
          SendCVH: "SEND CV",
          DaliRole: "AI Engineer & Co-Founder",
          MilanRole: "Frontend Engineer & Co-Founder",
          IlijaRole: "Backend Engineer & Co-Founder",
          DaliInfo:
            "With over 8 years in pharmaceutical manufacturing, Dalibor managed GxP production plants as an engineer and led many projects to the success with local and global project teams. He developed deep expertise in AI/ML during his part-time studies and engineered the AI engine that powers our tool.",
          MilanInfo:
            "A front-end developer with over 6 years of experience in the construction industry, Milan specializes in UI development and ERP consulting. He supports the team with his front-end expertise in the no-code AI tool for Predictive Maintenance.",
          IlijaInfo:
            "A Java software engineer with over 6 years of experience across various industries, Ilija specializes in cybersecurity, Unix security, and computer vision. He joined the team for his backend and software deployment skills.",

          // CONTACT //
          SendUaMessageH: "SEND US A MESSAGE",
          FullName: "Full Name",
          CompanyName: "Company name",
          Email: "Email",
          Phone: "Phone",
          Message: "Message",
          SendH: "SEND",
          Optional: "Optional",

          // PARTNERS //
          Patrners: "Patrners",
          MeetOurPartnersH: "MEET OUR PARTNERS",
        },
      },
    },
  });

export default i18n;
