import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeLanguage } from "./ChangeLanguage";
import { Twirl as Hamburger } from "hamburger-react";
import logo from "../img/logo.svg";

export const AppHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLinkClick = (to) => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    } else {
      document.getElementById(to)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="App-header">
      <nav className="nav">
        <Link
          to="landing"
          smooth={true}
          duration={500}
          className="logo"
          onClick={() => handleLinkClick("landing")}
        >
          <img src={logo} alt="predsense logo" />
        </Link>
        <Hamburger className="hamburger" toggled={isOpen} toggle={setIsOpen} />
        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          <Link
            className="li"
            to="vision"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("vision")}
          >
            {t("Vision")}
          </Link>
          <Link
            className="li"
            to="idea"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("idea")}
          >
            {t("Product")}
          </Link>
          <Link
            className="li"
            to="roadmap"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("roadmap")}
          >
            {t("OurJourney")}
          </Link>
          <Link
            className="li"
            to="team"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("team")}
          >
            {t("Team")}
          </Link>
          <Link
            className="li"
            to="partners"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("partners")}
          >
            {t("Partners")}
          </Link>
          <Link
            className="li"
            to="contact"
            smooth={true}
            duration={500}
            onClick={() => handleLinkClick("contact")}
          >
            {t("Contact")}
          </Link>
          <div className="top-content">
            <ChangeLanguage />
          </div>
        </div>
      </nav>
    </header>
  );
};
