import React from "react";

const Impressum = () => {
  return (
    <div className="section">
      <h1>Impressum</h1>
      <div>
        <p>
          <strong>PredSense GmbH</strong>
          <br /> Ladestraße 2
          <br /> 6300 Wörgl
          <br /> Österreich
        </p>
        <p>
          Tel: +43 660 1599064
          <br /> E-Mail:{" "}
          <a href="mailto:info@predsense.ai">info@predsense.ai</a>
          <br /> Kontakt: <a href="/#contact">Zum Kontaktformular</a>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Unternehmensgegenstand:&nbsp;</strong>Datenverarbeitung und
          Informationstechnik
          <br />
          <strong>UID-Nummer: </strong>ATU81277823
          <br />
          <strong>FN: </strong>637384f
          <br />
          <strong>Firmenbuchgericht:</strong> Landesgericht Innsbruck
          <br />
          <strong>Firmensitz:</strong> 6300 Wörgl
        </p>
        <p>
          <strong>Mitgliedschaft bei der Wirtschaftsorganisation:</strong>{" "}
          Wirtschaftskammer Österreich
          <br />
          <strong>Rechtsvorschriften:</strong>&nbsp;Gewerbeordnung:{" "}
          <a href="http://www.ris.bka.gv.at" target="_blank" rel="noreferrer">
            www.ris.bka.gv.at
          </a>
          <br />
          <strong>Aufsichtsbehörde / Gewerbebehörde:</strong>
          &nbsp;Bezirkshauptmannschaft Innsbruck
        </p>
        <p>
          <strong>Angaben zur Online-Streitbeilegung:&nbsp;</strong>
          <br /> Pflichtinformation nach der Verordnung (EU) Nr. 524/2013 des
          Europäischen Parlaments und Rats: Link zur Homepage der Stelle für die
          Online-Beilegung verbraucherrechtlicher Streitigkeiten der
          Europäischen Kommission:{" "}
          <a
            href="http://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noreferrer"
          >
            http://ec.europa.eu/consumers/odr/
          </a>
          . Für erste Fragen zu einer möglichen Streitschlichtung stehen wir
          Ihnen unter <a href="mailto:info@predsense.ai">info@predsense.ai</a>{" "}
          zur Verfügung.
          <br /> &nbsp;
        </p>
        <h2>Medieninhaber</h2>
        <p>
          <strong>PredSense GmbH</strong>
          <br /> Ladestraße 2
          <br /> 6300 Wörgl
          <br /> Österreich
        </p>
        <p>
          <strong>Unternehmensgegenstand des Medieninhabers:&nbsp;</strong>
          <br /> Datenverarbeitung und Informationstechnik
        </p>
        <p>
          <strong>
            Grundlegende inhaltliche Richtung der Website und unserer
            Newsletter:
          </strong>
        </p>
        <ul>
          <li>Präsentation der PredSense GmbH</li>
          <li>
            Informationen über die angebotenen Produkte und Dienstleistungen
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Impressum;
